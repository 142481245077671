import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HeaderContainer from "../../Components/HeaderContainer";
import { PrimaryButton } from "../../Components/Buttons";
import { getData, pushToLocalStorage, pushUpdateEmail } from "../../Utils/API";
import BasicModal from "../../Components/BasicModal";

export default function Update() {
  const [update, setUpdate] = useState(null);
  const [slug, setSlug] = useState(null);
  const [downloadEmailInput, setDownloadEmailInput] = useState("");
  const [downloadEmailInputError, setDownloadEmailInputError] = useState(false);
  const [downloadEmailInputErrorMessage, setDownloadEmailInputErrorMessage] =
    useState("");
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  useEffect(() => {
    console.log("UseEffect");
    const tmpSlugs = window.location.pathname.split("/");

    // const scriptQuery = qs.stringify({
    //   filters: {
    //     slug: tmpSlugs[2],
    //   },
    // });

    // axios
    //   .get(
    //     `${process.env.REACT_APP_CMS_URL}/api/updates?${scriptQuery}&populate=*`
    //   )
    //   .then((res) => {
    //     setUpdate(res.data.data[0]);
    //     setSlug(tmpSlugs[2]);
    //   });

    getData({
      populate: "*",
      filters: {
        slug: tmpSlugs[2],
      },
      collection: "updates",
    }).then((res) => {
      setUpdate(res.data.data[0]);
      setSlug(tmpSlugs[2]);
    });
  }, []);

  // useEffect(() => {
  //   console.log("UseEffect");
  //   if (!update) return;
  //   const dataToPush = {
  //     id: update.id,
  //     type: "update",
  //     name: update.attributes.name,
  //     slug: window.location.pathname,
  //   };
  //   pushToLocalStorage(dataToPush);
  // }, [update]);

  //   const renderScripts = () => {
  //     return update.attributes.script.map((script) => {
  //       return (
  //         <SingleScriptContent>
  //           <h3>{script.title}</h3>
  //           <div dangerouslySetInnerHTML={{ __html: script.description }}></div>
  //           <PrimaryButton
  //             onClick={() => {
  //               window.open(
  //                 `${process.env.REACT_APP_CMS_URL}${script.script_file.data[0].attributes.url}`,
  //                 "_blank"
  //               );
  //             }}
  //           >
  //             Download
  //           </PrimaryButton>
  //         </SingleScriptContent>
  //       );
  //     });
  //   };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Container>
      {/* {console.log("lesson", lesson)}
      {console.log("tutorial", tutorial)}
      {console.log("content", content)}
      {console.log("backandsad", backAndForward)} */}
      {!update ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeaderContainer>
            <Pagination>
              <Link to="/updates" className="back">
                Tutorials <span>{">"}</span>
              </Link>
              <Link to={`/updates/${slug}`}>{update.attributes.name}</Link>
            </Pagination>
            <HeaderContent>
              <HeaderTextContent>
                <h1>{update.attributes.name}</h1>
              </HeaderTextContent>
              <PrimaryButton
                onClick={() => {
                  setDownloadModalOpen(true);
                }}
              >
                Download Release
              </PrimaryButton>
            </HeaderContent>
          </HeaderContainer>
          <LessonContent>
            <Content
              id="divToPrint"
              dangerouslySetInnerHTML={{
                __html: update.attributes.ReleaseNotes,
              }}
            ></Content>
          </LessonContent>
        </>
      )}
      {downloadModalOpen && (
        <BasicModal
          onClose={() => {
            setDownloadEmailInput("");
            setDownloadEmailInputError(false);
            setDownloadEmailInputErrorMessage("");
            setDownloadModalOpen(false);
          }}
        >
          <div>
            <h2>Download Release</h2>
            <DownloadDescription>
              You can download the latest release by inserting your email below,
              and clicking on the download button.
            </DownloadDescription>
            <Row>
              <InputRow>
                <label htmlFor="email">Email</label>
                <EmailInput
                  value={downloadEmailInput}
                  type="email"
                  onChange={(e) => {
                    setDownloadEmailInput(e.target.value);
                    setDownloadEmailInputError(false);
                    setDownloadEmailInputErrorMessage("");
                  }}
                />
              </InputRow>
              <PrimaryButton
                onClick={() => {
                  console.log("Download");
                  //check if input is valid

                  if (!downloadEmailInput) {
                    setDownloadEmailInputError(true);
                    setDownloadEmailInputErrorMessage("Please insert an email");
                    return;
                  }

                  if (!validateEmail(downloadEmailInput)) {
                    setDownloadEmailInputError(true);
                    setDownloadEmailInputErrorMessage(
                      "Please insert a valid email"
                    );
                    return;
                  }

                  pushUpdateEmail(
                    downloadEmailInput,
                    update.attributes.name + " " + update.attributes.version
                  )
                    .then((res) => {
                      console.log("pushUpdateEmail", res);

                      if (res.status === 200) {
                        window.open(
                          `${update.attributes.file.data[0].attributes.url}`,
                          "_blank"
                        );
                        setDownloadEmailInput("");
                        setDownloadEmailInputError(false);
                        setDownloadEmailInputErrorMessage("");
                        setDownloadModalOpen(false);
                      } else {
                        setDownloadEmailInputError(true);
                        setDownloadEmailInputErrorMessage(
                          "Something went wrong, please try again later or contact us at support@nordbo-robotics.com"
                        );
                      }
                    })
                    .catch((err) => {
                      setDownloadEmailInputError(true);
                      setDownloadEmailInputErrorMessage(
                        "Something went wrong, please try again later or contact us at support@nordbo-robotics.com"
                      );
                    });
                }}
              >
                Download Release
              </PrimaryButton>
            </Row>
            <ErrorMessage>
              {downloadEmailInputError && downloadEmailInputErrorMessage}
            </ErrorMessage>
          </div>
        </BasicModal>
      )}
    </Container>
  );
}

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;

    &.back {
      margin-right: 4px;
      opacity: 0.8;
    }
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
`;

const LessonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 32px;
  video {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  p {
    text-align: left;
  }
  li {
    text-align: left;
    margin-bottom: 8px;
  }
  ul,
  ol {
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;

  img {
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4 {
    text-align: left;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 32px;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderTextContent = styled.div``;

const DownloadDescription = styled.p`
  margin-bottom: 12px;
  max-width: 500px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 12px 0;
  width: 100%;
  max-width: 1200px;
  padding: 0 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 250px;
  margin-right: 40px;
  margin-top: 40px;
  label {
    margin-bottom: 8px;
  }
`;

const EmailInput = styled.input`
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.text};
  padding: 0 12px;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.background};
  ::placeholder {
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.8;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin: 0;
  margin-top: 8px;
  font-size: 14px;
  text-align: left;
  padding-left: 12px;
`;
