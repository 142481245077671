import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../icons/close.svg";

export default function BasicModal({ children, onClose }) {
  return (
    <ModalOverlay>
      <ModalContent>
        <Closebutton onClick={onClose}>
          <CloseIcon />
        </Closebutton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalContent = styled.div`
  z-index: 101;
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  position: relative;
`;

const Closebutton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
`;
