import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import ContentWithSidebar from "../../Components/ContentWithSidebar";
import HeaderContainer from "../../Components/HeaderContainer";
import Playlist from "../../Components/Playlist";
import { BackButton, NextButton } from "../../Components/Buttons";

import { getData, pushToLocalStorage } from "../../Utils/API";

export default function Lesson() {
  const navigate = useNavigate();

  const testRef = useRef(null);
  const videoRef = useRef(null);
  const [nestedHeadings, setNestedHeadings] = useState([]);
  const [tutorial, setTutorial] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const [lessonSlug, setLessonSlug] = useState(null);
  const [tutorialSlug, setTutorialSlug] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentVideoSrc, setCurrentVideoSrc] = useState(null);

  useEffect(() => {
    console.log("UseEffect");
    const tmpSlugs = window.location.pathname.split("/");
    getData({
      filters: {
        slug: tmpSlugs[2],
      },
      populate: [
        "lessons",
        "image",
        "description",
        "product",
        "robot",
        "tracker",
        "lessons.tutorials",
        "lessons.tutorials.video",
      ],
      collection: "tutorial-collections",
    }).then((res) => {
      setLessonSlug(tmpSlugs[2]);
      setTutorialSlug(tmpSlugs[3]);
      setPlaylist(res.data.data[0].attributes.lessons);
      let allLessons = [];
      res.data.data[0].attributes.lessons.forEach((item) => {
        allLessons = [...allLessons, ...item.tutorials.data];
      });
      setTutorial(allLessons);
      setCurrentIndex(
        allLessons.findIndex((item) => item.attributes.slug == tmpSlugs[3])
      );
      const tmpSelectedLesson =
        allLessons[
          allLessons.findIndex((item) => item.attributes.slug == tmpSlugs[3])
        ];
      setSelectedLesson(tmpSelectedLesson);
      if (tmpSelectedLesson.attributes.video.data) {
        console.log("Here", tmpSelectedLesson.attributes.video.data);
        setCurrentVideoSrc(
          tmpSelectedLesson.attributes.video.data[0].attributes.url
        );
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log("useEffec loading");
    if (videoRef.current) {
      videoRef.current.load();
    }
    window.scrollTo(0, 0);
  }, [selectedLesson]);

  return (
    <Container>
      {!tutorial &&
      !playlist &&
      !lessonSlug &&
      !tutorialSlug &&
      loading &&
      !selectedLesson &&
      !currentIndex ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeaderContainer>
            <Pagination>
              <Link to="/tutorials" className="back">
                Tutorials <span>{">"}</span>
              </Link>
              <Link to={`/tutorials/${lessonSlug}/${tutorialSlug}`}>
                {selectedLesson.attributes.name}
              </Link>
            </Pagination>
            <h1>{selectedLesson.attributes.name}</h1>
          </HeaderContainer>
          <ContentWithSidebar>
            <AroundPlaylist>
              <Playlist
                items={playlist}
                courseSlug={lessonSlug}
                sticky="40px"
                onClick={(slug) => {
                  setLoading(true);
                  setCurrentIndex(
                    tutorial.findIndex((item) => item.attributes.slug == slug)
                  );
                  setSelectedLesson(
                    tutorial[
                      tutorial.findIndex((item) => item.attributes.slug == slug)
                    ]
                  );
                  setLoading(false);
                }}
              />
            </AroundPlaylist>
            <LessonContent>
              {selectedLesson.attributes.video.data && (
                <video controls ref={videoRef}>
                  <source
                    src={`${selectedLesson.attributes.video.data[0].attributes.url}`}
                    type="video/mp4"
                  />
                </video>
              )}

              <Content
                dangerouslySetInnerHTML={{
                  __html: selectedLesson.attributes.Content,
                }}
                ref={testRef}
              ></Content>
              <BackAndForwardButtons>
                {currentIndex > 0 ? (
                  <BackButton
                    onClick={() => {
                      setLoading(true);
                      navigate(
                        `/tutorials/${lessonSlug}/${
                          tutorial[currentIndex - 1].attributes.slug
                        }`
                      );
                      setCurrentIndex(currentIndex - 1);
                      setSelectedLesson(tutorial[currentIndex - 1]);
                      setLoading(false);
                    }}
                  >
                    {tutorial[currentIndex - 1].attributes.name}
                  </BackButton>
                ) : (
                  <EmptyButtonFiller />
                )}
                {currentIndex < tutorial.length - 1 ? (
                  <NextButton
                    onClick={() => {
                      setLoading(true);
                      navigate(
                        `/tutorials/${lessonSlug}/${
                          tutorial[currentIndex + 1].attributes.slug
                        }`
                      );
                      setCurrentIndex(currentIndex + 1);
                      setSelectedLesson(tutorial[currentIndex + 1]);
                      setLoading(false);
                    }}
                  >
                    {tutorial[currentIndex + 1].attributes.name}
                  </NextButton>
                ) : (
                  <EmptyButtonFiller />
                )}
              </BackAndForwardButtons>
            </LessonContent>
            <TableOfContents>
              {/* <Headings headings={nestedHeadings} /> */}
            </TableOfContents>
          </ContentWithSidebar>
        </>
      )}
    </Container>
  );
}

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
      font-size: 0.7rem;
      margin-bottom: 4px;
    }

    &.back {
      margin-right: 4px;
      opacity: 0.8;
    }
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
`;

const LessonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 32px;
  video {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  p {
    text-align: left;
  }
  li {
    text-align: left;
    margin-bottom: 8px;
  }
  ul,
  ol {
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
  }
`;

const TableOfContents = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  ul {
    list-style: none;
  }
`;

const Content = styled.div`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;

  img {
    width: 100%;
    height: auto;
    image-size: cover;
  }
  h1,
  h2,
  h3,
  h4 {
    text-align: left;
  }
`;

const TableItemTop = styled.li`
  font-size: 12px;
  text-align: left;
`;
const TableItemMid = styled.li`
  padding-left: 20px;
  font-size: 12px;
  text-align: left;
`;

const BackAndForwardButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const EmptyButtonFiller = styled.div`
  width: 100%;
`;

const AroundPlaylist = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
