import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as HelpIcon } from "../icons/help.svg";
import { ReactComponent as ManualIcon } from "../icons/manual.svg";
import { ReactComponent as MarketingIcon } from "../icons/marketing.svg";
import { ReactComponent as ScriptIcon } from "../icons/script.svg";
import { ReactComponent as UpdateIcon } from "../icons/update.svg";
import { ReactComponent as HamburgerIcon } from "../icons/hamburger.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import logo from "../assets/logo.png";

export default function Navigation() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Nav>
        <LogoContainer>
          <Link to="/">
            <Logo src={logo}></Logo>
          </Link>
        </LogoContainer>
        <NavList>
          <NavLabel>
            <h4>Academy</h4>
          </NavLabel>
          <NavListItem>
            <NavigationLink to="/tutorials">
              <HelpIcon />
              <span>Tutorials</span>
            </NavigationLink>
          </NavListItem>
          <NavListItem>
            <NavigationLink to="/documentation">
              <ManualIcon />
              <span>Documentation</span>
            </NavigationLink>
          </NavListItem>
        </NavList>
        <NavList>
          <NavLabel>
            <h4>Content</h4>
          </NavLabel>
          <NavListItem>
            <NavigationLink to="/marketing-content">
              <MarketingIcon />
              <span>Marketing Content</span>
            </NavigationLink>
          </NavListItem>
          <NavListItem>
            <NavigationLink to="/updates">
              <UpdateIcon />
              <span>Updates</span>
            </NavigationLink>
          </NavListItem>
          {/* <NavListItem>
            <NavigationLink to="/robot-scripts">
              <ScriptIcon /> <span>Robot Scripts</span>
            </NavigationLink>
          </NavListItem> */}
        </NavList>
      </Nav>
      <MobileNav>
        <HamburgerMenu>
          <Link
            to="/"
            onClick={(e) => {
              setOpen(false);
            }}
          >
            <Logo src={logo}></Logo>
          </Link>
          <HamburgerMenuIcon onClick={() => setOpen(!open)}>
            {open ? <CloseIcon /> : <HamburgerIcon />}
          </HamburgerMenuIcon>
        </HamburgerMenu>
        <MobileNavigationContainer className={open ? "open" : ""}>
          <MobileNavigationLink
            to="/tutorials"
            onClick={(e) => {
              setOpen(false);
            }}
          >
            <HelpIcon />
            <span>Tutorials</span>
          </MobileNavigationLink>
          <MobileNavigationLink
            to="/documentation"
            onClick={(e) => {
              setOpen(false);
            }}
          >
            <ManualIcon />
            <span>Documentation</span>
          </MobileNavigationLink>
          <MobileNavigationLink
            to="/marketing-content"
            onClick={(e) => {
              setOpen(false);
            }}
          >
            <MarketingIcon />
            <span>Marketing Content</span>
          </MobileNavigationLink>
          <MobileNavigationLink
            to="/updates"
            onClick={(e) => {
              setOpen(false);
            }}
          >
            <UpdateIcon />
            <span>Updates</span>
          </MobileNavigationLink>
          {/* <MobileNavigationLink
            to="/robot-scripts"
            onClick={(e) => {
              setOpen(false);
            }}
          >
            <ScriptIcon /> <span>Robot Scripts</span>
          </MobileNavigationLink> */}
        </MobileNavigationContainer>
      </MobileNav>
    </>
  );
}

const Logo = styled.img`
  width: 100px;
`;

const Nav = styled.nav`
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  border-right: 1px solid ${({ theme }) => theme.colors.border};

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavigationLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  text-align: left;
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-radius: 8px;
  span {
    margin-left: 10px;
  }
  svg {
    path {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.ctaLight};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.cta};
      }
    }
    span {
      color: ${({ theme }) => theme.colors.cta};
    }
  }
`;

const NavLabel = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  list-style: none;
  width: 100%;
  margin-bottom: 12px;
  h4 {
    font-size: 0.9rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
  }
`;
const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;
  list-style: none;
  padding: 16px;
`;

const NavListItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  list-style: none;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 18px;
  margin: 0;
`;

const MobileNav = styled.nav`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    height: 60px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.backgroundLighter};
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    z-index: 100;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

const HamburgerMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 60px;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
`;

const HamburgerMenuIcon = styled.div`
  width: 30px;
  height: 30px;
`;

const MobileNavigationContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  z-index: 100;
  position: fixed;
  top: 60px;
  right: -100vw;
  height: calc(100vh - 60px);
  padding-top: 20px;

  transition: all 0.3s ease-in-out;
  &.open {
    right: 0;
  }
`;

const MobileNavigationLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  text-align: left;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0;
  padding-left: 16px;
  padidng-right: 16px;
  span {
    margin-left: 10px;
  }
  svg {
    path {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.ctaLight};
  }
`;
