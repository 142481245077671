import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Playlist({
  items,
  title,
  courseSlug,
  sticky,
  onClick,
}) {
  const [activeSlug, setActiveSlug] = useState(null);
  const tmpSlugs = window.location.pathname.split("/");
  useEffect(() => {
    console.log("UseEffect");

    setActiveSlug(tmpSlugs[3]);
  }, [tmpSlugs]);

  const renderPlaylist = (items) => {
    return items.map((item) => {
      return (
        <Items
          key={item.name + (Math.random() + 1).toString(36).substring(7)}
          className="collection-section"
        >
          <SingleitemHeadline
            key={item.name + (Math.random() + 1).toString(36).substring(7)}
          >
            {item.name}
          </SingleitemHeadline>
          {item.tutorials.data.map((lesson) => (
            <Singleitem
              key={
                lesson.attributes.name +
                (Math.random() + 1).toString(36).substring(7)
              }
              to={`/tutorials/${courseSlug}/${lesson.attributes.slug}`}
              className={lesson.attributes.slug === activeSlug ? "active" : ""}
              onClick={() => onClick(lesson.attributes.slug)}
            >
              {lesson.attributes.name}
            </Singleitem>
          ))}
        </Items>
      );
    });
  };
  return (
    items.length > 0 && (
      <AroundPlaylist
        style={{
          position: sticky ? "sticky" : "relative",
          top: sticky ? sticky : "auto",
        }}
      >
        {renderPlaylist(items)}
      </AroundPlaylist>
    )
  );
}

const AroundPlaylist = styled.div`
  width: 100%;
  max-width: 400px;
  text-align: left;
  h3 {
    margin-bottom: 4px;
    font-size: 1rem;
    font-weight: 600;
  }
  p {
    margin-bottom: 12px;
  }

  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.1);
  border: 1px solid ${({ theme }) => theme.colors.border};

  .collection-section {
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  color: ${({ theme }) => theme.colors.text};
  text-align: left;
  width: 100%;
  top: 0;
  left: 0;
`;

const Singleitem = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  font-weight: 400;
  padding: 4px 0;
  position: relative;
  margin: 0;
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  font-size: 0.9rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.cta};
    text-decoration: none;
    font-weight: 400;
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.cta};
    color: ${({ theme }) => theme.colors.contrastText};
  }
`;

const SingleitemHeadline = styled.h4`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  font-weight: 600;
  padding-left: 16px;
  padding-top: 12px;
  font-size: 0.8rem;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding-bottom: 12px;
  position: relative;
  opacity: 0.8;
  background-color: ${({ theme }) => theme.colors.background};
  &not(:first-child) {
    margin-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.border};
  }
`;
