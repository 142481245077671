import React from "react";
import styled from "styled-components";

export default function ContentWithSidebar({ children }) {
  return <Content>{children}</Content>;
}

const Content = styled.div`
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "sidebar main table-of-contents";
  grid-gap: 60px;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 80px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 80px);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "main";
    grid-gap: 0;
    padding-top: 0;
  }
`;
