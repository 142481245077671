import React from "react";
import styled from "styled-components";

export default function HeaderContainer({ children, fullWidth }) {
  return (
    <HeaderStyled>
      <Inner className={fullWidth && "full-width"}>{children}</Inner>
    </HeaderStyled>
  );
}

const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  text-align: left;
  width: 100%;
  background: linear-gradient(
    268.91deg,
    rgba(2, 59, 165, 0.05) 0%,
    rgba(91, 144, 244, 0.05) 71.35%
  );
  border: 1px solid ${({ theme }) => theme.colors.background};
  box-shadow: inset 0px -29px 44px -40px rgba(4, 16, 39, 0.09);
  h1 {
    font-weight: 400;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  p {
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.text};
    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Inner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  &.full-width {
    width: 100%;
    max-width: 100%;
  }
`;
