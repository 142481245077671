import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { getData } from "../../Utils/API";
import Checkbox from "../../Components/Checkbox";
import ContentCard from "../../Components/ContentCard";
import HeaderContainer from "../../Components/HeaderContainer";
import MediaViewerModal from "../../Components/MediaViewerModal";
import { NeutralButton, PrimaryButton } from "../../Components/Buttons";
import { saveAs } from "file-saver";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
const intitialSidebarItems = {
  product: [],
  robot: [],
  tracker: [],
  application: [],
};
const initialFilters = {
  type: [],
  product: [],
  robot: [],
  tracker: [],
  application: [],
};

export default function MarketingContent() {
  const navigate = useNavigate();
  const [marketingContent, setMarketingContent] = useState(null);
  const [allMarketingContent, setAllMarketingContent] = useState([]); // used for filtering
  const [sidebarContent, setSidebarContent] = useState(intitialSidebarItems);
  const [filters, setFilters] = useState(initialFilters);
  const [openCard, setOpenCard] = useState(null);
  const [collection, setCollection] = useState([]);
  const [showCollection, setShowCollection] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [searchParams] = useSearchParams();
  const filtersCollectionRef = useRef(null);
  const collectionWrapperRef = useRef(null);
  const filterButtonRef = useRef(null);

  var JSZip = require("jszip");
  let zip = new JSZip();
  let collectionZip = zip.folder(
    `Nordbo-download-${Math.random().toString(36).substring(7)}`
  );

  useEffect(() => {
    console.log("UseEffect");
    let productParams = searchParams.get("product");
    let robotParams = searchParams.get("robot");
    let applicationParams = searchParams.get("application");
    let trackerParams = searchParams.get("tracker");
    let typeParams = searchParams.get("type");
    let fileSlugParams = searchParams.get("file");
    if (fileSlugParams) {
      fileSlugParams = fileSlugParams.split(",");
    }
    if (productParams) {
      productParams = productParams.split(",");
    }
    if (robotParams) {
      robotParams = robotParams.split(",");
    }
    if (applicationParams) {
      applicationParams = applicationParams.split(",");
    }
    if (trackerParams) {
      trackerParams = trackerParams.split(",");
    }
    if (typeParams) {
      typeParams = typeParams.split(",");
    }
    getData({
      collection: "marketing-files",
      populate: `
        slug,
        name,
        createdAt,
        updatedAt,
        media,
        applications,
        robots,
        trackers,
        media_type,
        products
      `,
    }).then((res) => {
      let products = res.data.data.map((content) => {
        return content.attributes.products.data.map((product) => {
          return {
            slug: product.attributes.slug,
            name: product.attributes.name,
          };
        });
      });
      let robots = res.data.data.map((content) => {
        return content.attributes.robots.data.map((robot) => {
          return { slug: robot.attributes.slug, name: robot.attributes.name };
        });
      });
      let applications = res.data.data.map((content) => {
        return content.attributes.applications.data.map((application) => {
          return {
            slug: application.attributes.slug,
            name: application.attributes.name,
          };
        });
      });
      let trackers = res.data.data.map((content) => {
        return content.attributes.trackers.data.map((tracker) => {
          return {
            slug: tracker.attributes.slug,
            name: tracker.attributes.name,
          };
        });
      });
      let mediaTypes = res.data.data.map((content) => {
        return {
          type: content.attributes.media_type.data.attributes.type,
        };
      });

      products = products.flat();
      robots = robots.flat();
      applications = applications.flat();
      trackers = trackers.flat();
      mediaTypes = mediaTypes.flat();

      products = products.filter(
        (product, index, self) =>
          index === self.findIndex((t) => t.slug === product.slug)
      );
      robots = robots.filter(
        (robot, index, self) =>
          index === self.findIndex((t) => t.slug === robot.slug)
      );
      applications = applications.filter(
        (application, index, self) =>
          index === self.findIndex((t) => t.slug === application.slug)
      );
      trackers = trackers.filter(
        (tracker, index, self) =>
          index === self.findIndex((t) => t.slug === tracker.slug)
      );
      mediaTypes = mediaTypes.filter(
        (mediaType, index, self) =>
          index === self.findIndex((t) => t.type === mediaType.type)
      );

      setSidebarContent((prevState) => ({
        ...prevState,
        product: products,
        robot: robots,
        application: applications,
        tracker: trackers,
        mediaTypes: mediaTypes,
      }));

      setMarketingContent(res.data.data);
      setAllMarketingContent(res.data.data);

      if (productParams) {
        setFilters((prevState) => ({
          ...prevState,
          product: productParams,
        }));
      }
      if (robotParams) {
        setFilters((prevState) => ({
          ...prevState,
          robot: robotParams,
        }));
      }
      if (applicationParams) {
        setFilters((prevState) => ({
          ...prevState,
          application: applicationParams,
        }));
      }
      if (trackerParams) {
        setFilters((prevState) => ({
          ...prevState,
          tracker: trackerParams,
        }));
      }
      if (typeParams) {
        setFilters((prevState) => ({
          ...prevState,
          type: typeParams,
        }));
      }
    });
  }, []);

  useEffect(() => {
    console.log("UseEffect Filter");
    let filtered = false;
    let filteredContent = [...allMarketingContent];

    if (filters.type.length > 0) {
      filteredContent = filteredContent.filter((content) => {
        return filters.type.includes(
          content.attributes.media_type.data.attributes.type
        );
      });
      filtered = true;
    }
    if (filters.product.length > 0) {
      filteredContent = filteredContent.filter((content) => {
        return content.attributes.products.data.some((product) =>
          filters.product.includes(product.attributes.slug)
        );
      });
      filtered = true;
    }
    if (filters.robot.length > 0) {
      filteredContent = filteredContent.filter((content) => {
        return content.attributes.robots.data.some((robot) =>
          filters.robot.includes(robot.attributes.slug)
        );
      });
      filtered = true;
    }
    if (filters.application.length > 0) {
      filteredContent = filteredContent.filter((content) => {
        return content.attributes.applications.data.some((application) =>
          filters.application.includes(application.attributes.slug)
        );
      });
      filtered = true;
    }
    if (filters.tracker.length > 0) {
      filteredContent = filteredContent.filter((content) => {
        return content.attributes.trackers.data.some((tracker) =>
          filters.tracker.includes(tracker.attributes.slug)
        );
      });
      filtered = true;
    }

    if (filtered === false) {
      setMarketingContent(allMarketingContent);
    } else {
      setMarketingContent(filteredContent);
    }
    addParamsToURL();
  }, [filters]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        collectionWrapperRef.current &&
        !collectionWrapperRef.current.contains(event.target)
      ) {
        if (
          filterButtonRef.current &&
          !filterButtonRef.current.contains(event.target)
        ) {
          setShowCollection(false);
        }
      }
      if (
        filtersCollectionRef.current &&
        !filtersCollectionRef.current.contains(event.target)
      ) {
        if (
          filterButtonRef.current &&
          !filterButtonRef.current.contains(event.target)
        ) {
          setShowFilters(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    collectionWrapperRef,
    openCard,
    showCollection,
    filtersCollectionRef,
    showFilters,
  ]);

  const arrowKeyFunction = useCallback(
    (event) => {
      if (!openCard) return;
      if (showCollection) return;
      if (event.keyCode === 39) {
        //Do whatever when esc is pressed
        if (openCard.index + 1 < marketingContent.length) {
          setOpenCard("overlay");
          setTimeout(() => {
            setOpenCard({
              content: marketingContent[openCard.index + 1],
              index: openCard.index + 1,
            });
          }, 100);
        }
      }
      if (event.keyCode === 37) {
        //Do whatever when esc is pressed
        if (openCard.index - 1 >= 0) {
          setOpenCard("overlay");
          setTimeout(() => {
            setOpenCard({
              content: marketingContent[openCard.index - 1],
              index: openCard.index - 1,
            });
          }, 100);
        }
      }
    },
    [openCard, marketingContent, showCollection]
  );

  useEffect(() => {
    console.log("UseEffect");
    document.addEventListener("keydown", arrowKeyFunction, false);

    return () => {
      document.removeEventListener("keydown", arrowKeyFunction, false);
    };
  }, [arrowKeyFunction, openCard]);

  /// On download button click, loop through / download images using the key given from storage.list in onload function
  async function handleDownloadMultipleClick(event) {
    event.preventDefault();
    setDownloading(true);

    for (let i = 0; i < collection.length; i++) {
      console.log("Downloading file", collection[i]);
      await DownloadFileZip(collection[i]);
    }
    console.log("collectionZip", collectionZip);
    /// waits for "await DownloadFileFromS3, then executes the save as which saves the zipped folder created in "DownloadFileFromS3"
    await zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(
        content,
        `Nordbo-download-${Math.random().toString(36).substring(7)}.zip`
      );
    });
    setDownloading(false);
    setCollection([]);
    setShowCollection(false);
  }

  // function doThehiding() {
  //   if (openCard) {
  //     console.log("Trying to close card with openCard", openCard);
  //     // setOpenCard(null);
  //   } else {
  //     console.log(
  //       "Trying to close card with closedCard",
  //       openCard,
  //       showCollection
  //     );
  //     if (showCollection) {
  //       setShowCollection(false);
  //     }
  //   }
  // }

  // /// download each file from s3 and put it in the zip folder
  async function DownloadFileZip(fileToDownload) {
    return axios({
      url: fileToDownload.attributes.media.data[0].attributes.url,
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let fileName =
          fileToDownload.attributes.media.data[0].attributes.hash +
          fileToDownload.attributes.media.data[0].attributes.ext;

        console.log("blob", blob);

        collectionZip.file(fileName, blob);
        return true;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function addParamsToURL() {
    if (
      filters.application.length === 0 &&
      filters.product.length === 0 &&
      filters.robot.length === 0 &&
      filters.tracker.length === 0 &&
      filters.type.length === 0
    ) {
      navigate(`/marketing-content`);
      return;
    }
    navigate(
      `/marketing-content?product=${filters.product}&robot=${filters.robot}&application=${filters.application}&tracker=${filters.tracker}&type=${filters.type}`
    );

    console.log("addParamsToURL", filters);
  }

  if (
    !marketingContent ||
    !sidebarContent.robot ||
    !sidebarContent.tracker ||
    !sidebarContent.application ||
    !sidebarContent.product ||
    !sidebarContent.mediaTypes ||
    !allMarketingContent
  )
    return <div>Lodaing</div>;
  return (
    <Container>
      <FiltersOverlay className={showFilters && "active"}></FiltersOverlay>
      <SidebarFilterContainer
        className={showFilters && "active"}
        ref={filtersCollectionRef}
      >
        <ResetFilters
          onClick={() => {
            setFilters(initialFilters);
          }}
        >
          Clear filters
        </ResetFilters>
        <FilterCollection>
          <h3>FileTypes</h3>
          <FilterList>
            {sidebarContent.mediaTypes.map((mediaType) => {
              return (
                <Checkbox
                  label={mediaType.type}
                  name={mediaType.type}
                  checked={filters.type.includes(mediaType.type)}
                  key={mediaType.type}
                  onChecked={() => {
                    if (filters.type.includes(mediaType.type)) {
                      setFilters({
                        ...filters,
                        type: filters.type.filter(
                          (type) => type !== mediaType.type
                        ),
                      });

                      //add param to url
                    } else {
                      setFilters({
                        ...filters,
                        type: [...filters.type, mediaType.type],
                      });

                      //add param to url
                    }
                  }}
                />
              );
            })}
          </FilterList>
        </FilterCollection>
        <FilterCollection>
          <h3>Products</h3>
          <FilterList>
            {sidebarContent.product.map((product) => {
              return (
                <Checkbox
                  key={product.slug}
                  label={product.name}
                  name={product.slug}
                  checked={
                    filters.product.includes(product.slug) ? true : false
                  }
                  onChecked={() => {
                    if (filters.product.includes(product.slug)) {
                      setFilters({
                        ...filters,
                        product: filters.product.filter(
                          (slug) => slug !== product.slug
                        ),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        product: [...filters.product, product.slug],
                      });
                    }
                  }}
                />
              );
            })}
          </FilterList>
        </FilterCollection>
        <FilterCollection>
          <h3>Robots</h3>
          <FilterList>
            {sidebarContent.robot.map((robot) => {
              return (
                <Checkbox
                  key={robot.slug}
                  label={robot.name}
                  name={robot.slug}
                  checked={filters.robot.includes(robot.slug) ? true : false}
                  onChecked={() => {
                    if (filters.robot.includes(robot.slug)) {
                      setFilters({
                        ...filters,
                        robot: filters.robot.filter(
                          (slug) => slug !== robot.slug
                        ),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        robot: [...filters.robot, robot.slug],
                      });
                    }
                  }}
                />
              );
            })}
          </FilterList>
        </FilterCollection>

        <FilterCollection>
          <h3>application</h3>
          <FilterList>
            {sidebarContent.application.map((application) => {
              return (
                <Checkbox
                  key={application.slug}
                  label={application.name}
                  name={application.slug}
                  checked={
                    filters.application.includes(application.slug)
                      ? true
                      : false
                  }
                  onChecked={() => {
                    if (filters.application.includes(application.slug)) {
                      setFilters({
                        ...filters,
                        application: filters.application.filter(
                          (slug) => slug !== application.slug
                        ),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        application: [...filters.application, application.slug],
                      });
                    }
                  }}
                />
              );
            })}
          </FilterList>
        </FilterCollection>

        <FilterCollection>
          <h3>Trackers</h3>
          <FilterList>
            {sidebarContent.tracker.map((tracker) => {
              return (
                <Checkbox
                  key={tracker.slug}
                  label={tracker.name}
                  name={tracker.slug}
                  checked={
                    filters.tracker.includes(tracker.slug) ? true : false
                  }
                  onChecked={() => {
                    if (filters.tracker.includes(tracker.slug)) {
                      setFilters({
                        ...filters,
                        tracker: filters.tracker.filter(
                          (slug) => slug !== tracker.slug
                        ),
                      });
                    } else {
                      setFilters({
                        ...filters,
                        tracker: [...filters.tracker, tracker.slug],
                      });
                    }
                  }}
                />
              );
            })}
          </FilterList>
        </FilterCollection>
      </SidebarFilterContainer>
      <FilterButton
        ref={filterButtonRef}
        onClick={() => setShowFilters(!showFilters)}
      >
        Filters
      </FilterButton>
      <ContentContainer>
        <HeaderContainer fullWidth={true}>
          <h1>Marketing Content</h1>
          <p>
            Use the filters on the left to find the content you are looking for.
            <br></br>
            You can then download or share the content by selecting it and
            adding to the collection.
          </p>
        </HeaderContainer>
        <Overlay className={showCollection && "overlay-active"}>
          <Collection
            className={showCollection && "active"}
            ref={collectionWrapperRef}
          >
            <CollectionToolTip
              className={showCollection && "active-tooltip"}
              onClick={() => {
                setShowCollection(!showCollection);
              }}
            >
              {showCollection ? (
                "Close"
              ) : (
                <>
                  <h4>Collection</h4>
                  {collection.length > 0 && <p>{collection.length} item</p>}
                </>
              )}
            </CollectionToolTip>
            <CollectionHeader>
              <h2>Collection</h2>
            </CollectionHeader>
            <CollectionList>
              {collection.map((item, index) => {
                return (
                  <CollectionItem key={item.attributes.slug}>
                    <CollectionSingleContent
                      onClick={() => {
                        setOpenCard({ content: item, index });
                      }}
                    >
                      <h4>{item.attributes.name}</h4>
                    </CollectionSingleContent>
                    <p
                      onClick={() => {
                        setCollection(
                          collection.filter((content) => {
                            return content !== item;
                          })
                        );
                      }}
                    >
                      remove
                    </p>
                  </CollectionItem>
                );
              })}
            </CollectionList>
            <ButtonsContainer>
              <NeutralButton
                onClick={() => {
                  setShowCollection(!showCollection);
                }}
              >
                clear
              </NeutralButton>
              <PrimaryButton
                onClick={(e) => {
                  // setShowCollection(!showCollection);
                  handleDownloadMultipleClick(e);
                }}
                disabled={downloading || collection.length === 0}
              >
                {downloading ? "Downloading" : "Download"}
              </PrimaryButton>
            </ButtonsContainer>
          </Collection>
        </Overlay>
        <ContentList>
          <ContentCardHeader>
            <input
              type="checkbox"
              onChange={() => {
                if (collection.length === marketingContent.length) {
                  setCollection([]);
                } else {
                  setCollection(marketingContent);
                }
              }}
            />
            <h3>Name</h3>
            <h3>File Type</h3>
            <h3>Product</h3>
            <h3>Robot</h3>
            <h3>Application</h3>
            <h3>Tracker</h3>
          </ContentCardHeader>
          <ListContainer>
            {marketingContent.map((content, index) => {
              // if (!content.attributes.media.data) return null;
              return (
                <ContentCard
                  key={content.attributes.slug}
                  title={content.attributes.name}
                  slug={content.attributes.slug}
                  data={content.attributes.media.data}
                  product={content.attributes.products.data}
                  robot={content.attributes.robots.data}
                  application={content.attributes.applications.data}
                  tracker={content.attributes.trackers.data}
                  collection={collection}
                  onOpenCardClick={() => {
                    setOpenCard({ content, index });
                  }}
                  onSelectButtonClicked={(res) => {
                    if (res) {
                      // check if res exists in collection if not add it
                      if (!collection.includes(content)) {
                        setCollection([...collection, content]);
                      } else {
                        //remove from collection
                        setCollection(
                          collection.filter((item) => item !== content)
                        );
                      }
                    } else {
                      console.log("false");
                    }
                  }}
                />
              );
            })}
          </ListContainer>
        </ContentList>
      </ContentContainer>
      {openCard && (
        <MediaViewerModal
          isOpen={openCard}
          onClose={() => {
            setOpenCard(null);
          }}
          collectionText={
            !collection.includes(openCard.content)
              ? "Add to collection"
              : "Remove from collection"
          }
          onAddToCollection={() => {
            if (!collection.includes(openCard.content)) {
              setCollection([...collection, openCard.content]);
            } else {
              //remove from collection
              setCollection(
                collection.filter((item) => item !== openCard.content)
              );
            }
            setOpenCard(null);
          }}
          content={openCard}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  min-height: 100vh;
`;

const SidebarFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  height: 100%;
  max-height: 100vh;
  padding-bottom: 40px;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 250px;
  width: 230px;
  @media (max-width: 768px) {
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100%;
    width: 300px;
    z-index: 100;
    padding-bottom: 100px;
    &.active {
      left: 0;
    }
  }
`;

const FiltersOverlay = styled.div`
  display: none;
  @media (max-width: 768px) {
    transition: all 0.3s ease-in-out;
    display: block;
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;

    &.active {
      left: 0;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 230px;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const FilterCollection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  h3 {
    margin: 0;
    text-align: left;
    font-size: 0.8rem;
    margin-bottom: 7px;
    margin-top: 16px;
  }
`;

const FilterList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
`;

const ContentCardHeader = styled.div`
  display: grid;
  grid-template-columns: 24px 2fr 140px 1fr 1fr 1fr 80px;
  padding: 16px 8px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 0.9rem;
    margin: 0;
    text-align: left;
  }
  input {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

const Collection = styled.div`
  width: 400px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  border-radius: 4px;
  margin-bottom: 16px;
  position: fixed;
  top: 0;
  right: -400px;
  z-index: 100;
  transition: all 0.2s ease-in-out 0.2s;
  z-index: 100;

  &.active {
    right: 0;
  }
  @media (max-width: 768px) {
    width: calc(100vw - 50px);
  }
`;

const CollectionToolTip = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  width: 100px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: 16px;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.background};
  position: absolute;
  top: 30px;
  left: -100px;
  transition: all 0.2s ease-in-out;
  z-index: 100;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // &.active-tooltip {
  //   width: 50px;
  //   left: -50px;
  // }

  // @media (max-width: 768px) {
  //   bottom: 0px;
  //   top: auto;
  //   width: 50vw;
  //   left: calc(-50vw - 35px);
  //   height: 60px;
  //   border-radius: 0;

  //   &.active-tooltip {
  //     opacity: 0;
  //   }
  // }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  transition: all 0.5s ease-in-out;

  &.overlay-active {
    right: 0;
  }
`;

const CollectionHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 16px;
  padding-top: 24px;
  height: 70px;
  h2 {
    margin: 0;
    text-align: left;
    font-size: 1.2rem;
  }
`;

const CollectionList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow-y: scroll;
  height: calc(100% - 100px - 70px);
  width: 94%;
  margin: 0 auto;
  margin-top: 16px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background};
`;

const CollectionItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  border: 1px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundLighter};
  }

  p {
    margin: 0;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.textLight};
    cursor: pointer;
  }
`;

const CollectionSingleContent = styled.div`
  display: flex;
  align-items: center;
  h4 {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 400;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  padding-top: 0;
  height: 80px;
  padding-top: 16px;

  button {
    height: 60px;
  }
`;

const FilterButton = styled.button`
  width: 100vw;
  height: 60px;
  position: fixed;
  bottom:0;
  left;0;
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0;
  z-index: 100;

  @media (min-width: 768px) {
    display: none;
  }
  `;

const ResetFilters = styled.div`
  padding: 16px;
  padding-bottom: 0;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.textLight};
  cursor: pointer;
  text-align: left;
  margin-bottom: 16px;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
  position: absolute;
  top: 0;
  right: 0;
`;
