import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HeaderContainer from "../../Components/HeaderContainer";
import { getData } from "../../Utils/API";

export default function Manuals() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [collapse, setCollapse] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    console.log("UseEffect");
    getData({
      populate: "slug, product, product.slug, name",
      collection: "manuals",
    }).then((productsRes) => {
      setProducts(productsRes.data.data);
      let tmpCats = [];
      productsRes.data.data.forEach((product) => {
        //check if is in categories
        tmpCats.push({
          slug: product.attributes.product.data.attributes.slug,
          name: product.attributes.product.data.attributes.name,
        });
      });

      tmpCats = tmpCats.filter(
        (v, i, a) => a.findIndex((t) => t.slug === v.slug) === i
      );
      setCategories(tmpCats);
      setLoading(false);
    });
  }, []);
  return (
    <Container>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeaderContainer>
            <Pagination>
              <Link to="/documentation">Documentation</Link>
            </Pagination>
            <h1>Nordbo Robotics Documentation</h1>
            <p>
              Start by choosing your product below, <br></br>and then choose the
              manual fitting your product version.
            </p>
          </HeaderContainer>
          <ProductsContainer>
            {categories.map((category) => {
              return (
                <Product
                  key={category.slug}
                  onClick={() => {
                    if (collapse !== category.slug) {
                      setCollapse(category.slug);
                    } else {
                      setCollapse(false);
                    }
                  }}
                >
                  <h3>{category.slug === "all" ? "General" : category.name}</h3>
                  <Collapse className={collapse === category.slug && "active"}>
                    {products.map((product) => {
                      if (
                        product.attributes.product.data.attributes.slug ===
                        category.slug
                      ) {
                        return (
                          <ProductLink
                            key={product.id}
                            to={`/documentation/${product.attributes.product.data.attributes.slug}/${product.attributes.slug}`}
                          >
                            {product.attributes.name}
                          </ProductLink>
                        );
                      }
                    })}
                  </Collapse>
                </Product>
              );
            })}
          </ProductsContainer>
        </>
      )}
    </Container>
  );
}

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;

    &.back {
      margin-right: 4px;
      opacity: 0.8;
    }
  }
`;

const Container = styled.div``;

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const Product = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.1);
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  margin-bottom: 12px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  margin-right: 24px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 1px solid transparent;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 400;
    text-align: left;
    margin: 0;
    padding: 0;
    padding-left: 16px;
    padding-bottom: 12px;
  }
  p {
    opacity: 0.8;
    margin-top: 8px;
  }

  &:hover {
    box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.1);
    background-color: ${({ theme }) => theme.colors.backgroundLighter};
  }
`;

const Collapse = styled.div`
  width: 100%;
  height: 0;
  opacity: 0;
  display: none;

  transition: opacity 0.1s ease-in-out;

  &.active {
    height: 100%;
    opacity: 1;
    display: block;
  }
`;

const ProductLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  padding-left: 16px;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;
