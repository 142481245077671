import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NeutralButton, BlackButton } from "../Components/Buttons";
import { Link } from "react-router-dom";
import { ReactComponent as HelpIcon } from "../icons/help.svg";
import { ReactComponent as ManualIcon } from "../icons/manual.svg";
import { ReactComponent as MarketingIcon } from "../icons/marketing.svg";
import { ReactComponent as ScriptIcon } from "../icons/script.svg";
import { ReactComponent as UpdateIcon } from "../icons/update.svg";
const Home = () => {
  const [history, setHistory] = useState(null);

  useEffect(() => {
    const tmpHistory = JSON.parse(localStorage.getItem("history"));
    setHistory(tmpHistory);
  }, []);
  return (
    <Container>
      <Row>
        <ProductsContainer>
          <h1>Get started with our Products!</h1>
          <p>
            Learn how to setup, use and get the most out of Mimic and Craftmate
          </p>
          <BottomContent>
            <NeutralButton>Get Started</NeutralButton>
          </BottomContent>
        </ProductsContainer>
        <MarketingContainer>
          <h1>Want to see our products in action?</h1>
          <p>
            Take a look at our handpicked marketing content for all our products
          </p>
          <BottomContent>
            <NeutralButton style={{ marginLeft: "auto" }}>
              Take a look
            </NeutralButton>
          </BottomContent>
        </MarketingContainer>
      </Row>
      <RowOfThree>
        <Card>
          <h2>Your recent activity</h2>
          {history &&
            history.map((item) => {
              return (
                <LinkStyled to={item.slug} key={item.slug}>
                  {item.type === "manuals" && <ManualIcon />}
                  {item.type === "marketing" && <MarketingIcon />}
                  {item.type === "update" && <UpdateIcon />}
                  {item.type === "script" && <ScriptIcon />}
                  {item.type === "tutorial" && <HelpIcon />}
                  <p>{item.name}</p>
                </LinkStyled>
              );
            })}
        </Card>
        <ColumnOfCards>
          <Card>
            <h2>Newest updates</h2>
            <BottomContent>
              <BlackButton>See all updates</BlackButton>
            </BottomContent>
          </Card>
          <Card>
            <h2>In-depth manuals</h2>
            <p>See detailed manuals for our products, by version. </p>
            <BottomContent>
              <BlackButton>Take a look</BlackButton>
            </BottomContent>
          </Card>
        </ColumnOfCards>
        <ColumnOfCards>
          <Card>
            <h2>Download our brand package</h2>
            <p>
              Do you need our logos, colors or fonts, or just a description
              about Nordbo Robotics and our products.
            </p>
            <BottomContent>
              <BlackButton>Download</BlackButton>
            </BottomContent>
          </Card>
          <Card>
            <h2>Robot Scripts</h2>
            <BottomContent>
              <BlackButton>See more</BlackButton>
            </BottomContent>
          </Card>
        </ColumnOfCards>
      </RowOfThree>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  padding: 40px;
  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const ProductsContainer = styled.div`
  width: calc(100% - 100px);
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.backgroundLighter};
  border-radius: 24px 0px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px;
  position: relative;
  h1 {
    font-size: 32px;
    text-align: left;
  }
  p {
    text-align: left;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -99px;
    width: 100px;
    height: 100%;
    background-image: url(${require("../assets/corner.png")});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
  }

  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  @media (max-width: 768px) {
    border-radius: 24px;
    width: 100%;
    &:after {
      display: none;
    }
  }
`;

const MarketingContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.cta};
  color: ${({ theme }) => theme.colors.backgroundLighter};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px;
  border-radius: 24px 24px 24px 0px;
  height: 100%;
  text-align: left;
  h1 {
    font-size: 32px;
    text-align: left;
  }
  p {
    margin-bottom: 40px;
  }
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -99px;
    width: 100px;
    height: 100%;
    background-image: url(${require("../assets/box2.png")});
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    z-index: 0;
  }

  @media (max-width: 768px) {
    border-radius: 24px;
    &:after {
      display: none;
    }
  }
`;

const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 24px;
  height: 320px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    height: auto;
  }
`;

const BottomContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;

  p {
    margin-left: 24px;
  }
`;

const RowOfThree = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  height: 320px;
  margin-top: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: auto;
  }
`;

const Card = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  position: relative;
  text-align: left;

  box-shadow: 0px 4px 8px rgba(4, 21, 53, 0.03);
  h2 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
  }
  p {
    margin-bottom: 40px;
    font-size: 1rem;
  }
`;

const ColumnOfCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  grid-gap: 24px;
`;

const LinkStyled = styled(Link)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 18px 0px;
  p {
    margin-bottom: 0px;
    margin-left: 8px;
    font-weight: 400;
    font-size: 1rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundLighter};
  }
  &:last-child {
    border-bottom: none;
  }
`;

export default Home;
