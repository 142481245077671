import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HeaderContainer from "../../Components/HeaderContainer";
import { getData } from "../../Utils/API";

export default function ManualsByProduct() {
  const [product, setProduct] = useState([]);
  const [productSlug, setProductSlug] = useState(null);
  const [manuals, setManuals] = useState([]);

  useEffect(() => {
    console.log("UseEffect");
    const tmpSlugs = window.location.pathname.split("/");
    getData({
      populate: "*",
      filters: {
        slug: tmpSlugs[2],
      },
      collection: "products",
    }).then((productsRes) => {
      setProduct(productsRes.data.data[0]);
      setProductSlug(tmpSlugs[2]);
      getData({
        populate: "*",
        filters: {
          product: productsRes.data.data[0].id,
        },
        collection: "manuals",
      }).then((manualsRes) => {
        setManuals(manualsRes.data.data);
      });
    });
  }, []);
  return (
    <Container>
      {!product || !productSlug ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeaderContainer>
            <Pagination>
              <Link to="/manuals" className="back">
                Manuals <span>{">"}</span>
              </Link>
              <Link to={`/manuals/${productSlug}`}>
                {product.attributes.name}
              </Link>
            </Pagination>
            <h1> {product.attributes.name} Manuals</h1>
            <p>Choose the manual fitting your product version.</p>
          </HeaderContainer>
          <ProductsContainer>
            <ManualsTableHeader>
              <h4>Name</h4>
              <h4>Version</h4>
            </ManualsTableHeader>
            {manuals.map((manual) => (
              <Manual
                to={`/manuals/${productSlug}/${manual.attributes.slug}`}
                key={manual.attributes.slug}
              >
                <h3>{manual.attributes.name}</h3>
                <p>{manual.attributes.version}</p>
              </Manual>
            ))}
          </ProductsContainer>
        </>
      )}
    </Container>
  );
}

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;

    &.back {
      margin-right: 4px;
      opacity: 0.8;
    }
  }
`;

const Container = styled.div``;

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 1200px;
  width: 100%;
`;
const ManualsTableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 18px;
  text-align: left;
  border-radius: 8px;
`;

const Manual = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 18px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.05);
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  margin-bottom: 32px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  margin-right: 24px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.backgroundLighter};

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 1.1rem;
    font-weight: 400;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  p {
    opacity: 0.8;
  }

  &:hover {
    scale: 1.01;
    box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.1);
    border: 1px solid ${({ theme }) => theme.colors.cta};
    background-color: ${({ theme }) => theme.colors.backgroundLighter};
  }
`;
