import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HeaderContainer from "../../Components/HeaderContainer";
import { PrimaryButton } from "../../Components/Buttons";
import { getData, pushToLocalStorage } from "../../Utils/API";

export default function Manual() {
  const [productSlug, setProductSlug] = useState(null);
  const [manualSlug, setManualSlug] = useState(null);
  const [manual, setManual] = useState(null);
  const testRef = useRef(null);
  useEffect(() => {
    console.log("UseEffect");
    const tmpSlugs = window.location.pathname.split("/");
    setProductSlug(tmpSlugs[2]);
    setManualSlug(tmpSlugs[3]);

    getData({
      populate: "*",
      filters: {
        slug: tmpSlugs[3],
      },
      collection: "manuals",
    }).then((manualsRes) => {
      setManual(manualsRes.data.data[0]);
    });
  }, []);

  useEffect(() => {
    console.log("UseEffect");
    if (!manual) return;
    const dataToPush = {
      id: manual.id,
      type: "manuals",
      name: manual.attributes.name,
      slug: window.location.pathname,
    };
    pushToLocalStorage(dataToPush);
  }, [manual]);

  const downloadItem = async () => {
    var mywindow = window.open("", "PRINT", "height=1920,width=1080");

    mywindow.document.write("<html>");
    mywindow.document.write("<body>");
    mywindow.document.write(testRef.current.innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
  };

  return (
    <Container>
      {!manual || !productSlug || !manualSlug ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeaderContainer>
            <Pagination>
              <Link to="/documentation" className="back">
                Documentation <span>{">"}</span>
              </Link>
              <Link to={`/documentation/${productSlug}/${manualSlug}`}>
                {manual.attributes.name}
              </Link>
            </Pagination>
            <HeaderContent>
              <HeaderTextContent>
                <h1> {manual.attributes.name} Manuals</h1>
                <p>Version {manual.attributes.version}</p>
              </HeaderTextContent>
              <PrimaryButton
                onClick={() => {
                  // downloadItem();
                  window.print();
                }}
              >
                Download as PDF
              </PrimaryButton>
            </HeaderContent>
          </HeaderContainer>
          <ContentContainer>
            <Content
              id="divToPrint"
              dangerouslySetInnerHTML={{ __html: manual.attributes.content }}
              ref={testRef}
            ></Content>
          </ContentContainer>
        </>
      )}
    </Container>
  );
}

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  a {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;

    &.back {
      margin-right: 4px;
      opacity: 0.8;
    }
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 32px;
  video {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  p {
    text-align: left;
  }
  li {
    text-align: left;
    margin-bottom: 8px;
  }
  ul,
  ol {
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 900px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin: 0 auto;

  img {
    max-width: 100%;
    height: auto;
    image-size: cover;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: left;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 32px;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderTextContent = styled.div``;
