import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function ContentCard({
  title,
  slug,
  product,
  robot,
  data,
  application,
  tracker,
  onOpenCardClick,
  collection,
  onSelectButtonClicked,
  resetSelects,
}) {
  const randomString = () => {
    return Math.random().toString(36).substring(7);
  };

  const getFyleType = () => {
    if (!data) {
      return null;
    }
    if (
      data[0].attributes.ext === ".png" ||
      data[0].attributes.ext === ".jpg" ||
      data[0].attributes.ext === ".jpeg" ||
      data[0].attributes.ext === ".gif" ||
      data[0].attributes.ext === ".svg" ||
      data[0].attributes.ext === ".bmp" ||
      data[0].attributes.ext === ".arw"
    ) {
      return "image";
    } else if (
      data[0].attributes.ext === ".mp4" ||
      data[0].attributes.ext === ".mov" ||
      data[0].attributes.ext === ".avi" ||
      data[0].attributes.ext === ".wmv" ||
      data[0].attributes.ext === ".flv" ||
      data[0].attributes.ext === ".mkv"
    ) {
      return "video";
    } else if (
      data[0].attributes.ext === ".ppt" ||
      data[0].attributes.ext === ".pptx"
    ) {
      return "presentation";
    } else {
      return "document";
    }
  };

  const renderPills = (data) => {
    const dataToReturn = [];
    for (let index = 0; index < 2; index++) {
      if (data[index]) {
        dataToReturn.push(
          <Pill key={data[index].attributes.name + randomString()}>
            {data[index].attributes.name}
          </Pill>
        );
      } else {
        break;
      }
    }
    data.length > 2 &&
      dataToReturn.push(
        <Pill key={randomString()}>
          +{data.length - 2}
          <DropDownVisual>
            {data.map((item) => {
              return (
                <DropDownItem key={item.attributes.name + randomString()}>
                  {item.attributes.name}
                </DropDownItem>
              );
            })}
          </DropDownVisual>
        </Pill>
      );
    return dataToReturn;
  };
  return (
    <ContentListItem>
      <AroundEachItem className="hide-on-mobile">
        <input
          type="checkbox"
          key={Math.random()}
          checked={collection.find((item) => {
            return item.attributes.media.data[0].id === data[0].id;
          })}
          onChange={() => {
            onSelectButtonClicked(data);
          }}
        />
      </AroundEachItem>
      <AroundEachItem
        className="cursor-pointer"
        onClick={() => {
          onOpenCardClick();
        }}
      >
        <h3>{title}</h3>
      </AroundEachItem>
      <AroundEachItem
        className="cursor-pointer hide-on-mobile"
        onClick={() => {
          onOpenCardClick();
        }}
      >
        <h3>{getFyleType()}</h3>
      </AroundEachItem>
      <AroundEachItem
        className="cursor-pointer"
        onClick={() => {
          onOpenCardClick();
        }}
      >
        <Pills>{product.length > 0 ? renderPills(product) : ""}</Pills>
      </AroundEachItem>
      <AroundEachItem
        className="cursor-pointer hide-on-mobile"
        onClick={() => {
          onOpenCardClick();
        }}
      >
        <Pills>{robot.length > 0 ? renderPills(robot) : ""}</Pills>
      </AroundEachItem>
      <AroundEachItem
        className="cursor-pointer hide-on-mobile"
        onClick={() => {
          onOpenCardClick();
        }}
      >
        <Pills>{application.length > 0 ? renderPills(application) : ""}</Pills>
      </AroundEachItem>
      <AroundEachItem
        className="cursor-pointer hide-on-mobile"
        onClick={() => {
          onOpenCardClick();
        }}
      >
        <Pills>{tracker.length > 0 ? renderPills(tracker) : ""}</Pills>
      </AroundEachItem>
    </ContentListItem>
  );
}

const ContentListItem = styled.div`
  display: grid;
  grid-template-columns: 24px 2fr 140px 1fr 1fr 1fr 80px;
  padding: 16px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  justify-content: center;
  align-items: center;
  align-items: start;
  h3 {
    font-size: 0.9rem;
    margin: 0;
    font-weight: 400;
    text-align: left;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "title title"
      "type type"
      "product product"
      "robot robot"
      "application application"
      "tracker tracker";

    .hide-on-mobile {
      display: none;
    }
  }
`;

const AroundEachItem = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  input {
    width: 16px;
    height: 16px;
  }
  &.cursor-pointer {
    cursor: pointer;
  }
`;

const Pills = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Pill = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 4px;
  padding: 4px 8px;
  margin: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1;
  position: relative;
`;

const DropDownVisual = styled.div`
  position: absolute;
  top: 20px;
  left: -40px;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 4px;
  padding: 4px 8px;
  margin: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1;
  z-index: 1;
  box-shadow: 0 0 10px -2px ${({ theme }) => theme.colors.border};
  display: none;
  ${Pill}:hover & {
    display: block;
  }

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left:0;
    height: 20px;
    width:100%;
`;

const DropDownItem = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 4px;
  padding: 4px 8px;
  margin: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 1;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    display: block;
    cursor: default;
  }
`;
