import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HeaderContainer from "../../Components/HeaderContainer";
import { getData } from "../../Utils/API";

export default function Updates() {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    console.log("UseEffect");
    getData({ populate: "*", filters: {}, collection: "updates" }).then(
      (res) => {
        setUpdates(res.data.data);
      }
    );
    // axios
    //   .get(`https://${process.env.REACT_APP_CMS_URL}/api/updates?populate=*`)
    //   .then((res) => {
    //     setUpdates(res.data.data);
    //   });
  }, []);
  return (
    <Container>
      {!updates ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeaderContainer>
            <Pagination>
              <Link to="/robot-scripts">Updates</Link>
            </Pagination>
            <h1>Nordbo Robotics Product Updates</h1>
            <p>Choose the update for your product.</p>
          </HeaderContainer>
          <ProductsContainer>
            <ManualsTableHeader>
              <h4>Name</h4>
              <h4>Product</h4>
              <h4>Version</h4>
            </ManualsTableHeader>
            {updates.map((update) => (
              <Manual
                to={`/updates/${update.attributes.slug}`}
                key={update.attributes.slug}
              >
                <h3>{update.attributes.name}</h3>
                <p>{update.attributes.product.data.attributes.name}</p>
                <p>{update.attributes.version}</p>
              </Manual>
            ))}
          </ProductsContainer>
        </>
      )}
    </Container>
  );
}
const ManualsTableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  width: 100%;
  padding: 18px;
  text-align: left;
  border-radius: 8px;
`;

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;

    &.back {
      margin-right: 4px;
      opacity: 0.8;
    }
  }
`;

const Container = styled.div``;

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;
const Manual = styled(Link)`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  width: 100%;
  padding: 18px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.05);
  background-color: ${({ theme }) => theme.colors.backgroundLighter};
  margin-bottom: 32px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  margin-right: 24px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.backgroundLighter};

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 1.1rem;
    font-weight: 400;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  p {
    opacity: 0.8;
  }

  &:hover {
    scale: 1.01;
    box-shadow: 0px 7px 24px -10px rgba(2, 11, 28, 0.1);
    border: 1px solid ${({ theme }) => theme.colors.cta};
    background-color: ${({ theme }) => theme.colors.backgroundLighter};
  }
`;
