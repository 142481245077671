import React from "react";
import styled from "styled-components";

export default function Checkbox({ label, checked, onChecked, name }) {
  return (
    <FilterItem>
      <input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={() => onChecked()}
        id={name}
      />
      <label htmlFor={name}>{label}</label>
    </FilterItem>
  );
}

const FilterItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px 0;
  label {
    font-size: 0.9rem;
  }
  input {
    margin-right: 5px;
  }
`;
