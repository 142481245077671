import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import HeaderContainer from "../../Components/HeaderContainer";
import { ReactComponent as HelpIcon } from "../../icons/help.svg";
import Playlist from "../../Components/Playlist";
import { PrimaryButton } from "../../Components/Buttons";

import { getData } from "../../Utils/API";

export default function Tutorial({ navigation }) {
  const [lesson, setLesson] = useState(null);
  const [slug, setSlug] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("UseEffect");
    const tmpSlug = window.location.pathname.split("/")[2];

    getData({
      filters: {
        slug: tmpSlug,
      },
      populate: [
        "lessons",
        "image",
        "description",
        "product",
        "robot",
        "tracker",
        "lessons.tutorials",
      ],
      collection: "tutorial-collections",
    }).then((res) => {
      setLesson(res.data.data[0]);
      setSlug(tmpSlug);
    });
  }, []);

  return (
    <Container>
      {!lesson ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeaderContainer>
            <Pagination>
              <Link to="/tutorials" className="back">
                Tutorials <span>{">"}</span>
              </Link>
              <Link to={`/tutorials/${slug}`}>{lesson.attributes.name}</Link>
            </Pagination>
            <FlexRow>
              <LessonInfo>
                <img
                  src={lesson.attributes.image.data[0].attributes.url}
                  alt={lesson.attributes.name}
                />
                <LessonContent>
                  <h1>{lesson.attributes.name}</h1>
                  <p>{lesson.attributes.description}</p>
                  <Tags>
                    {lesson.attributes.product && (
                      <Tag>
                        <HelpIcon />
                        <p>{lesson.attributes.product.data.attributes.name}</p>
                      </Tag>
                    )}

                    {lesson.attributes.tracker && (
                      <Tag>
                        <HelpIcon />
                        <p>{lesson.attributes.tracker.data.attributes.name}</p>
                      </Tag>
                    )}

                    {lesson.attributes.robot && (
                      <Tag>
                        <HelpIcon />
                        <p>{lesson.attributes.robot.data.attributes.name}</p>
                      </Tag>
                    )}
                  </Tags>
                  <PrimaryButton
                    onClick={() =>
                      navigate(
                        `/tutorials/${slug}/${lesson.attributes.lessons[0].tutorials.data[0].attributes.slug}`
                      )
                    }
                  >
                    Start course
                  </PrimaryButton>
                </LessonContent>
              </LessonInfo>
              <Playlist
                items={lesson.attributes.lessons}
                courseSlug={slug}
                title="Course lessons"
              ></Playlist>
            </FlexRow>
          </HeaderContainer>
        </>
      )}
    </Container>
  );
}

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;
    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.cta};
    }

    &.back {
      margin-right: 4px;
      opacity: 0.8;
    }
  }
`;

const Container = styled.div``;

const LessonInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  width: 100%;
  padding-top: 16px;
  img {
    width: 300px;
    height: 250px;
    object-fit: cover;
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    img {
      width: 100%;
      height: auto;
    }
    padding-bottom: 24px;
  }
`;

const LessonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 16px;
  h1 {
    font-size: 1.7rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 16px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 40px;
`;

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 8px;
  opacity: 0.8;
  p {
    margin-left: 8px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
