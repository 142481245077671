import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
  body {
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
    transition: all 0.50s linear;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 1.5rem;
  }

  a {
    color: ${({ theme }) => theme.colors.cta};
    cursor: pointer;
  }
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
  }

  button {
    border: 0;
    display: inline-block;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 5px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.cta};
    color: ${({ theme }) => theme.colors.contrastText};
    font-family: ${({ theme }) => theme.font};
  }

  button.btn {
    background-color: ${({ theme }) => theme.colors.cta};
    color: ${({ theme }) => theme.colors.contrastText};
  }
`;
