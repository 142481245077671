import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { NeutralButton, PrimaryButton } from "./Buttons";
import docTypeImage from "../assets/doctype.png";
import axios from "axios";

const videoFormats = [".mp4", ".mov", ".avi", ".wmv", ".flv", ".mkv", ".webm"];
const imageFormats = [".png", ".jpg", ".jpeg", ".gif", ".svg"];
const powerPointFormats = [".ppt", ".pptx"];
const documentFormats = [
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".pdf",
  ".txt",
  ".csv",
  ".xml",
  ".json",
  ".zip",
  ".rar",
  ".tar",
  ".gz",
  ".7z",
  ".bz2",
  ".xz",
  ".mp3",
  ".ogg",
];

export default function MediaViewerModal({
  isOpen,
  onClose,
  content,
  onAddToCollection,
  collectionText,
}) {
  const wrapperRef = useRef(null);
  const videoRef = useRef(null);
  const [fileType, setFileType] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  useEffect(() => {
    console.log("UseEffect");
    if (content !== "overlay") {
      if (
        videoFormats.includes(
          content.content.attributes.media.data[0].attributes.ext
        )
      ) {
        setFileType("video");
      } else if (
        imageFormats.includes(
          content.content.attributes.media.data[0].attributes.ext
        )
      ) {
        setFileType("image");
      } else if (
        powerPointFormats.includes(
          content.content.attributes.media.data[0].attributes.ext
        )
      ) {
        setFileType("presentation");
      } else if (
        documentFormats.includes(
          content.content.attributes.media.data[0].attributes.ext
        )
      ) {
        setFileType("document");
      } else {
        setFileType("other");
      }
    }
  }, [content]);

  const downloadFile = (e) => {
    e.preventDefault();
    setIsDownloading(true);
    axios({
      url: content.content.attributes.media.data[0].attributes.url,
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${content.content.attributes.media.data[0].attributes.name}`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setIsDownloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("UseEffect");
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, onClose]);

  if (content === "overlay") {
    return <OverLay></OverLay>;
  }

  return isOpen ? (
    <OverLay>
      <Modal ref={wrapperRef}>
        <Content>
          {fileType === "video" && (
            <VideoContainer>
              <Video
                ref={videoRef}
                controls={true}
                autoplay
                onLoadedData={(e) => videoRef.current.play()}
              >
                <source
                  src={content.content.attributes.media.data[0].attributes.url}
                  type="video/mp4"
                />
              </Video>
            </VideoContainer>
          )}

          {fileType === "image" && (
            <ImageContainer>
              <img
                src={content.content.attributes.media.data[0].attributes.url}
                alt={content.content.attributes.name}
              />
            </ImageContainer>
          )}

          {fileType === "presentation" && (
            <PresentationContainer>
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${content.content.attributes.media.data[0].attributes.url}`}
                width="100%"
                title={content.content.attributes.name}
              ></iframe>
            </PresentationContainer>
          )}

          {fileType === "document" && (
            <DocumentContainer>
              <img src={docTypeImage} alt="Document type icon" />
            </DocumentContainer>
          )}

          <Title>{content.content.attributes.name}</Title>
        </Content>
        <Buttons>
          <NeutralButton
            onClick={() => {
              onClose();
            }}
          >
            Close
          </NeutralButton>
          <Row>
            <NeutralButton
              onClick={() => onAddToCollection()}
              className="hide-on-mobile"
            >
              {collectionText}
            </NeutralButton>
            <PrimaryButton
              onClick={(e) => downloadFile(e)}
              disabled={isDownloading}
              className="hide-on-mobile"
            >
              {isDownloading ? "Downloading..." : "Download"}
            </PrimaryButton>
          </Row>
        </Buttons>
      </Modal>
    </OverLay>
  ) : null;
}

const OverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  width: 700px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    width: calc(100vw - 32px);
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const VideoContainer = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Video = styled.video`
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const DocumentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 12px;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 12px;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const PresentationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 12px;

  iframe {
    width: 100%;
    height: 400px;
    border: 0;
    border-radius: 10px;
  }
`;

const Title = styled.h1`
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: left;
  padding-left: 15px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px;

  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    margin-left: 8px;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-end;
  }
`;
