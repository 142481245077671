import React, { useState, useEffect } from "react";

import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import Navigation from "./Components/Navigation";
import Tutorials from "./Views/Tutorials/Tutorials";
import Tutorial from "./Views/Tutorials/Tutorial";
import Manuals from "./Views/Manuals/Manuals";
import ManualsByProduct from "./Views/Manuals/ManualsByProduct";
import MarketingContent from "./Views/MarketingContent";
import RobotScripts from "./Views/Scripts/RobotScripts";
import RobotScript from "./Views/Scripts/RobotScript";
import Updates from "./Views/Updates/Updates";
import Update from "./Views/Updates/Update";
import Home from "./Views/Home";

import * as themes from "./Theme/schema.json";
import { setToLS } from "./Utils/localStorage";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./Theme/GlobalStyles";
import { useTheme } from "./Theme/UseTheme";
import WebFont from "webfontloader";
import Lesson from "./Views/Tutorials/Lesson";
import Manual from "./Views/Manuals/Manual";
function App() {
  setToLS("all-themes", themes.default);
  const { theme, themeLoaded, getFonts } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);

  useEffect(() => {
    console.log("UseEffect");
    setSelectedTheme(theme);
  }, [themeLoaded, theme]);

  //test

  return (
    <>
      {themeLoaded && (
        <ThemeProvider theme={selectedTheme}>
          <GlobalStyles />
          <Container className="App">
            <Navigation />
            <Content>
              <Routes>
                <Route path="/" element={<Navigate to="/tutorials" />} />
                <Route path="/tutorials" Component={Tutorials} />
                <Route path="/tutorials/:slug" Component={Tutorial} />
                <Route
                  path="/tutorials/:slug/:lessonUrl"
                  Component={(props) => <Lesson {...props} />}
                />
                <Route path="/documentation" Component={Manuals} />
                <Route
                  path="/documentation/:slug"
                  Component={ManualsByProduct}
                />
                <Route
                  path="/documentation/:slug/:manualUrl"
                  Component={Manual}
                />
                <Route path="/marketing-content" Component={MarketingContent} />
                <Route path="/robot-scripts" Component={RobotScripts} />
                <Route path="/robot-scripts/:slug" Component={RobotScript} />
                <Route path="/updates" Component={Updates} />
                <Route path="/updates/:slug" Component={Update} />
              </Routes>
            </Content>
          </Container>
          {/* <Footer /> */}
        </ThemeProvider>
      )}
    </>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100dvh;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.textColor};
  transition: all 0.5s linear;
`;

const Content = styled.div`
  width: calc(100% - 250px);
  margin-left: 250px;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    padding-top: 50px;
  }
`;

export default App;
